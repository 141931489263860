<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.helpedExercises.headers"
				:items="helpedExercises"
        sort-by="competitionDate"
        :sort-desc="true"
      >

				<template v-slot:item.view="{item}">
					<v-btn icon :to="{name: 'competition-resultLog', params: {EMUCId: item.id}}"><v-icon>fa-clock</v-icon></v-btn>
				</template>

      </v-data-table>

	</template>
	</div>
</template>

<script>

	export default {
		data: function(){
			return {
				helpedExercises: [],
        tables: {
          helpedExercises: {
            headers: [
              {
                text: "Verseny",
                align: "center",
                sortable: true,
                filterable: false,
                groupable: false,
                value: "competitionName"
              },
              {
                text: "Időpont",
                align: "center",
                sortable: true,
                filterable: false,
                groupable: false,
                value: "competitionDate"
              },
							{
                text: "Helyszín",
                align: "center",
                sortable: false,
                filterable: false,
                groupable: false,
                value: "competitionPlace"
              },
              {
                text: "Gyakorlat",
                align: "center",
                sortable: true,
                filterable: false,
                groupable: false,
                value: "exercise"
              },
              {
                text: "Korcsoport",
                align: "center",
                sortable: true,
                filterable: false,
                groupable: false,
                value: "ageGroup"
              },
							{
								text: "Eredményrögzítés",
								align: "center",
								sortable: false,
								filterable: false,
                groupable: false,
								value: "view"
							}
            ]
          }
        },
      }
		},
		computed: {
		},
		watch: {

		},
		methods: {

		},
		mounted(){
			this.axios({url: "competition/helper/list", method: "GET"}).then((response) => {
        if(response.data.success) {
					this.helpedExercises = response.data.data.helpedExercises.map(HE =>  { return {
            id: HE.id,
            competitionName: HE.Competition.name,
            competitionPlace: HE.Competition.place,
            competitionDate: HE.Competition.date,
            exercise: `${HE.Exercise_MeasurementUnit.Exercise.name} (${HE.Exercise_MeasurementUnit.MeasurementUnit.unit})`,
            ageGroup: `${HE.Age_Group.shortname} - ${HE.Age_Group.name}`
          }})
        }
			})
		}
	}
</script>
